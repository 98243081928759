import React, { useEffect, useState } from "react";
import BackButton from "../../../assets/image/BackButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../utils/services";
import {
  DEPARTMENT_LIST,
  UPDATE_INDIVIDUAL_BILL,
  VENDOR_ITEMS,
  VIEW_INDIVIDUAL_BILL,
} from "../../../utils/apiRoutes";
import { Helmet } from "react-helmet";
import moment from "moment";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import { ADMIN_MANAGER_ID } from "../../../utils/constant";
import EditIndividuallBillValidation from "../../../middleware/editIndividuallBillValidation";
import Loader from "../../common/Loader";

const EditIndividualBill = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [vendorName, setVendorName] = useState('')
  const [vendorItems, setVendorItems] = useState([]);
  const [lineLevelCheck, setLineLevelCheck] = useState(false);
  const [attachDocuments, setAttachDocuments] = useState(false)
  const [individualBillDocument, setIndividualBillDocument] = useState([])
  const [loader, setLoader] = useState(false)
  const [spinner, setSpinner] = useState(false);
  const [refNo, setReferenceNo] = useState('')
  const [billDate, setBillDate] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [itemDescription, setItemDescription] = useState([])
  const [deletedItemDescriptionId, setDeletedItemDescriptionId] = useState([]);
  // const [subsidiaryId, setSubsidiaryId] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const [gstTotal, setGstTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0)
  const [oldFiles, setOldFiles] = useState([])
  const [deletedFileId, setDeletedFileId] = useState([]);
  const [termCount, setTermCount] = useState([])
  const [billId, setBillId] = useState('')
  const [cgst, setCgst] = useState('');
  const [sgst, setSgst] = useState('');
  const [utgst, setUtgst] = useState('');
  const [igst, setIgst] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [departmentList, setDepartmentList] = useState([])
  const { errors, setErrors, validateForm } = EditIndividuallBillValidation();

  const minBillDate = moment().subtract(30, 'days')

  const handleViewData = async () => {
    try {
      setSpinner(true);
      const result = await getApi(`${VIEW_INDIVIDUAL_BILL}/${id}`);
      setSpinner(false);
      setVendorName(result.res.individualBill_details.LegalName);
      setReferenceNo(result.res.individualBill_details.ReferenceNo);
      setVendorId(result.res.individualBill_details.vendorId)
      setBillDate(moment(result.res.individualBill_details.BillDate).format().slice(0, 10));
      setBillId(result.res.individualBill_details.id)
      setDueDate(moment(result.res.individualBill_details.DueDate).format().slice(0, 10));
      setLineLevelCheck(result.res.individualBill_details.LineGST)
      setSubTotal(result.res.individualBill_details.InvoiceTotal);
      setGstTotal(result.res.individualBill_details.TaxSubtotal);
      setGrandTotal(result.res.individualBill_details.Total)
      setTermCount(result.res.individualBill_details.TermName)
      const modifiedInvoiceLines = result.res.individualBill_details.InvoiceLines.map(item => ({
        ...item,
        cgst: item.cgst !== null ? String(item.cgst) : '',   
        sgst: item.sgst !== null ? String(item.sgst) : '',   
        igst: item.igst !== null ? String(item.igst) : '',   
        utgst: item.utgst !== null ? String(item.utgst) : '', 
      }));
      setItemDescription(modifiedInvoiceLines)
      setOldFiles(result.res.individualBill_details.InvoiceDocuments);
      setCurrencySymbol(result.res.individualBill_details.CurrencySymbol);
      setDepartmentId(result.res.individualBill_details.DepartmentId);

      const { CGST, SGST, UTGST, IGST } = result.res.individualBill_details;
      setCgst(CGST !== null ? String(CGST) : '');
      setSgst(SGST !== null ? String(SGST) : '');
      setUtgst(UTGST !== null ? String(UTGST) : '');
      setIgst(IGST !== null ? String(IGST) : '');

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      handleViewData();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      if (vendorId) {

        const vendorItemsResult = await getApi(`${VENDOR_ITEMS}/${vendorId}`);
        setVendorItems(vendorItemsResult.res.itemsList)
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData()
  }, [vendorId])

  const handleList = async () => {
    try {

      let departmentResult = await getApi(DEPARTMENT_LIST)
      setDepartmentList(departmentResult.res.department_list)

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleList()
  }, [])

  const handleRefChange = (event) => {
    const { value } = event.target;
    setReferenceNo(value);
    // setErrors((prevErrors) => {
    //   const newErrors = { ...prevErrors };
    //   delete newErrors['reference'];
    //   return newErrors;
    // });
  };

  const handleDeptChange = (e) => {
    const { value } = e.target;
    setDepartmentId(value);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.departmentId;
      return newErrors;
    });
  };

  const handleBillDateChange = (e) => {
    const date = e.target.value;
    const term = termCount.replace(/\D/g, "");

    if (date) {
      const newDueDate = new Date(date);
      newDueDate.setDate(newDueDate.getDate() + parseInt(term));

      setBillDate(date);
      setDueDate(newDueDate.toISOString().split("T")[0]);

    }

  };

  const handleLineLevelChange = (e) => {

    const check = e.target.checked;
    setLineLevelCheck(check);

    if (check) {

      const updatedItemDescription = itemDescription.map(item => {
        const rate = item.Rate;
        const quantity = item.Quantity;
        const totalAmount = rate * quantity;
        item.Amount = totalAmount;

        const cgst = parseFloat(item.cgst) || 0;
        const sgst = parseFloat(item.sgst) || 0;
        const utgst = parseFloat(item.utgst) || 0;
        const igst = parseFloat(item.igst) || 0;

        const gstPercentage = cgst + sgst + utgst + igst;
        const gstAmount = totalAmount * (gstPercentage / 100);

        item.GST_Total = gstAmount.toFixed(2);
        return item;
      });

      setItemDescription(updatedItemDescription);
    }
  }

  const handleItemDescriptionChange = (index, field, value) => {

    const updatedItemDescription = [...itemDescription];

    let newValue;
    if (field === "Rate") {
      newValue = value.replace(/[^0-9.]/g, "");
      const dotindex = newValue.indexOf(".");
      if (dotindex !== -1 && newValue.length - dotindex > 3) {
        newValue = newValue.slice(0, dotindex + 3);
      }
    }
    else if (field === "Quantity") {
      newValue = value.replace(/[^0-9]/g, "");
    }
    else if (field === "cgst" || field === "sgst" || field === "utgst" || field === "igst") {
      newValue = value.replace(/[^0-9]/g, "");
    } else {
      newValue = value;
    }

    updatedItemDescription[index][field] = newValue;

    const rate = updatedItemDescription[index].Rate;
    const quantity = updatedItemDescription[index].Quantity;
    const totalAmount = (rate * quantity)
    updatedItemDescription[index].Amount = totalAmount;

    if (lineLevelCheck) {
      const cgst = parseFloat(updatedItemDescription[index].cgst) || 0;
      const sgst = parseFloat(updatedItemDescription[index].sgst) || 0;
      const utgst = parseFloat(updatedItemDescription[index].utgst) || 0;
      const igst = parseFloat(updatedItemDescription[index].igst) || 0;

      const gstPercentage = cgst + sgst + utgst + igst;
      const gstAmount = totalAmount * (gstPercentage / 100);

      updatedItemDescription[index].GST_Total = gstAmount.toFixed(2);
    }

    setItemDescription(updatedItemDescription)

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
      delete updatedErrors.itemDescription[index][field];
    }

    setErrors(updatedErrors);
  };

  const handleAdd = () => {

    setItemDescription([...itemDescription, {
      id: '',
      ItemId: '',
      Description: '',
      Rate: '',
      Quantity: '',
      cgst: '',
      sgst: '',
      igst: '',
      utgst: '',
      GST_Total: '',
      Amount: 0
    }])

  };

  const handleRemove = (index) => {

    if (itemDescription.length === 1) {
      alert("Atleast one item should be there");
      return;
    }

    const updatedItemDescription = [...itemDescription];
    const deletedId = updatedItemDescription[index].id;

    updatedItemDescription.splice(index, 1);

    setItemDescription(updatedItemDescription)
    setDeletedItemDescriptionId((prevDeletedIds) => [...prevDeletedIds, deletedId]);

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription.length > index) {
      updatedErrors.itemDescription.splice(index, 1);
    }

    setErrors(updatedErrors);
  };

  useEffect(() => {
    const calculateTotal = () => {

      const subTotal = itemDescription.reduce((acc, item) => acc + item.Amount, 0);
      setSubTotal(subTotal);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.subTotal;
        return newErrors;
      });

      if (!lineLevelCheck) {

        const modifiedCgst = parseFloat(cgst) ? parseFloat(cgst) : 0
        const modifiedSgst = parseFloat(sgst) ? parseFloat(sgst) : 0
        const modifiedUtgst = parseFloat(utgst) ? parseFloat(utgst) : 0
        const modifiedIgst = parseFloat(igst) ? parseFloat(igst) : 0

        const gstTotal = subTotal * ((modifiedCgst + modifiedSgst + modifiedUtgst + modifiedIgst) / 100)
        const grandTotal = subTotal + gstTotal

        setGstTotal(gstTotal)
        setGrandTotal(grandTotal)
      }
      else {

        const gstTotal = itemDescription.reduce((acc, item) => acc + parseFloat(item.GST_Total), 0);
        const grandTotal = subTotal + gstTotal

        setGstTotal(gstTotal)
        setGrandTotal(grandTotal)
      }

    }
    calculateTotal()
  }, [itemDescription, cgst, sgst, utgst, igst, lineLevelCheck])

  const handleTax = (e, field) => {
    const { value } = e.target

    const isValid = value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,3})(\.\d{0,2})?$/.test(value);

    if (isValid && hasValidDecimal) {
      if (field === "Cgst") {
        setCgst(value);
      } else if (field === "Sgst") {
        setSgst(value);
      } else if (field === "Utgst") {
        setUtgst(value);
      } else if (field === "Igst") {
        setIgst(value);
      }
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.tax;
      return newErrors;
    });
  }

  const handleFileInputChange = (e) => {
    const individualBillDocument = Array.from(e.target.files);
    setIndividualBillDocument(individualBillDocument);
    // // Remove errors for the files field
    // setErrors((prevErrors) => {
    //   const newErrors = { ...prevErrors };
    //   delete newErrors["files"];
    //   return newErrors;
    // });
  };

  const handleRemoveFile = (index, event) => {
    event.preventDefault();

    const updatedFiles = [...oldFiles];
    const deletedFileId = updatedFiles[index].FileId;

    updatedFiles.splice(index, 1);

    setOldFiles(updatedFiles);
    setDeletedFileId((prevDeletedIds) => [...prevDeletedIds, deletedFileId]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const isValid = validateForm(
        user.RoleId,
        departmentId,
        billDate,
        itemDescription,
        subTotal,
        cgst,
        sgst,
        utgst,
        igst,
        lineLevelCheck,
        oldFiles,
        individualBillDocument
      )

      if (isValid) {

        setLoader(true);

        let individualBillDocumentArray = [];

        const fileUploadPromises = individualBillDocument.map(async (file) => {
          const result = await uploadFile(
            "individualbills",
            vendorName,
            vendorId,
            file
          );
          const singlefile = {
            FileName: result.fileName,
            FilePath: result.filePath,
          };
          individualBillDocumentArray.push(singlefile);
        });
        await Promise.all(fileUploadPromises);

        let updatedItemDescription

        lineLevelCheck ?
          updatedItemDescription = itemDescription.map((row) => ({
            id: row.id,
            itemId: Number(row.ItemId),
            description: row.Description || null,
            rate: Number(row.Rate),
            quantity: Number(row.Quantity),
            cgst: row.cgst ? Number(row.cgst) : null,
            sgst: row.sgst ? Number(row.sgst) : null,
            utgst: row.utgst ? Number(row.utgst) : null,
            igst: row.igst ? Number(row.igst) : null,
            gstAmount: row.GST_Total ? Number(row.GST_Total) : null,
            totalAmount: Number(row.Amount)
          }))
          :
          updatedItemDescription = itemDescription.map((row) => ({
            id: row.id,
            itemId: Number(row.ItemId),
            description: row.Description || null,
            rate: Number(row.Rate),
            quantity: Number(row.Quantity),
            cgst: null,
            sgst: null,
            utgst: null,
            igst: null,
            gstAmount: null,
            totalAmount: Number(row.Amount)
          }))

        const result = await postApi(UPDATE_INDIVIDUAL_BILL, {
          vendorId: Number(vendorId),
          transactionId: transactionList[4].id,
          departmentId: departmentId,
          billId: billId,
          roleId: user.RoleId,
          referenceNo: refNo,
          billDate: billDate,
          dueDate: dueDate,
          paymentTypeId: 2,
          isGstInclude: false,
          isLineLevelGst: lineLevelCheck,
          itemDescription: updatedItemDescription,
          deletedItemDescriptionId: deletedItemDescriptionId,
          individualBillDocument: individualBillDocumentArray,
          deletedIndividualBillDocumentId: deletedFileId,
          cgst: lineLevelCheck ? null : cgst ? Number(cgst) : null,
          sgst: lineLevelCheck ? null : sgst ? Number(sgst) : null,
          utgst: lineLevelCheck ? null : utgst ? Number(utgst) : null,
          igst: lineLevelCheck ? null : igst ? Number(igst) : null,
          individualBillTotalAmount: subTotal,
          gstTotal: gstTotal,
          grandTotal: grandTotal
        });

        if (result.res.success) {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: result.res.message,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/individual-bill-list");
        }
      }
    }
    catch (error) {
      setLoader(false);
    };
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Standalone Bill</title>
      </Helmet>

      <Loader isLoading={spinner} />

      <div className="container-section">

        <div className="section-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div className="svgsize">
              <BackButton />
            </div>
          </button>
          <h3>Standalone Bill</h3>
        </div>

        <form>

          <div className="form-style">
            <div className="row">

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="vendorId" className="form-label">
                  Vendor<span className="required">*</span>
                </label>

                <input
                  type="text"
                  id="vendorId"
                  className="form-control"
                  name="vendorId"
                  value={vendorName}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="refNo" className="form-label">
                  Reference Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter reference number"
                  name="refNo"
                  value={refNo || ''}
                  onChange={handleRefChange}
                />
              </div>

              {user.RoleId === ADMIN_MANAGER_ID && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="departmentId" className="form-label">
                    Department<span className="required">*</span>
                  </label>
                  <select name="departmentId" id="departmentId" className="form-select" value={departmentId} onChange={handleDeptChange}>
                    <option value="">Select</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.DepartmentId}>
                        {item.DepartmentName}
                      </option>
                    ))}
                  </select>
                  {errors.departmentId && <span className="cstm-err">{errors.departmentId}</span>}
                </div>

              )}

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="billDate" className="form-label">
                  Bill Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  min={minBillDate.format('YYYY-MM-DD')}
                  className="form-control"
                  value={billDate}
                  name="billDate"
                  onChange={handleBillDateChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.billDate && (
                  <span className="cstm-err">{errors.billdate}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="dueDate" className="form-label">
                  Due Date
                </label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  className="form-control"
                  value={dueDate}
                  disabled
                />
              </div>

            </div>
          </div>

          <div className="form-style">
            <div className="col-xl-3 col-lg-4 col-md-3 mb-2">
              <div className="d-flex algin-item-center">
                <input
                  type="checkbox"
                  // name=""
                  // id=""
                  checked={lineLevelCheck}
                  onChange={handleLineLevelChange}
                />
                <label className="ms-2 form-check-label">Line Level GST</label>
              </div>
            </div>
            <div className="">
              <div className="section-sub-heading">
                <h3>Item Description</h3>
              </div>
              <div className="pur-item-list table-responsive tbl-body-shadow">
                <table className="w-100 table table-theme-1 mb-0">

                  <thead className="table-heads">
                    <tr>
                      <th className="text-center">Item</th>
                      <th className="text-center">Description</th>
                      <th className="text-center">Rate</th>
                      <th className="text-center">Quantity</th>
                      {lineLevelCheck &&
                        <>
                          <th className="text-center">CGST(%)</th>
                          <th className="text-center">SGST(%)</th>
                          <th className="text-center">UTGST(%)</th>
                          <th className="text-center">IGST(%)</th>
                          <th className="text-center">GST Amount</th>
                        </>
                      }
                      <th className="text-center">Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {itemDescription.map((item, index) => (
                      <tr key={index}>
                        <td className="custom-width">
                          <div className="position-relative">
                            <select className="form-select mb-20" name="ItemId"
                              value={item.ItemId}
                              onChange={(e) => handleItemDescriptionChange(index, "ItemId", e.target.value)}
                            >
                              <option value="">Select</option>
                              {vendorId && vendorItems.map((item, index) => (
                                <option key={index} value={item.itemId}>
                                  {item.itemname}
                                </option>
                              ))}
                            </select>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].ItemId && (
                                <span className="cstm-err">
                                  {errors.itemDescription[index].ItemId}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative des-width">
                            <textarea name="description" className="form-control" rows="1" cols="50" value={item.Description || ''}
                              onChange={(e) => handleItemDescriptionChange(index, "Description", e.target.value)}></textarea>
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" name="Rate"
                              autocomplete="off"
                              placeholder="Rate"
                              value={item.Rate}
                              onChange={(e) => handleItemDescriptionChange(index, "Rate", e.target.value)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].Rate && (
                                <span className="cstm-err">
                                  {errors.itemDescription[index].Rate}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" name="Quantity"
                              autocomplete="off"
                              placeholder="Quantity"
                              value={item.Quantity}
                              onChange={(e) => handleItemDescriptionChange(index, "Quantity", e.target.value)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].Quantity && (
                                <span className="cstm-err">
                                  {errors.itemDescription[index].Quantity}
                                </span>
                              )}
                          </div>
                        </td>
                        {lineLevelCheck &&
                          <>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="cgst"
                                  autocomplete="off"
                                  placeholder="CGST(%)"
                                  value={item.cgst !== null ? item.cgst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "cgst", e.target.value)}
                                  disabled={item.utgst || item.igst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].cgst && (
                                    <span className="cstm-err">
                                      {errors.itemDescription[index].cgst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="sgst"
                                  autocomplete="off"
                                  placeholder="SGST(%)"
                                  value={item.sgst !== null ? item.sgst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "sgst", e.target.value)}
                                  disabled={item.utgst || item.igst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].sgst && (
                                    <span className="cstm-err">
                                      {errors.itemDescription[index].sgst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="utgst"
                                  autocomplete="off"
                                  placeholder="UTGST(%)"
                                  value={item.utgst !== null ? item.utgst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "utgst", e.target.value)}
                                  disabled={item.cgst || item.sgst || item.igst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].utgst && (
                                    <span className="cstm-err">
                                      {errors.itemDescription[index].utgst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="igst"
                                  autocomplete="off"
                                  placeholder="IGST(%)"
                                  value={item.igst !== null ? item.igst : ''}
                                  onChange={(e) => handleItemDescriptionChange(index, "igst", e.target.value)}
                                  disabled={item.cgst || item.sgst || item.utgst}
                                >
                                </input>
                                {errors.itemDescription &&
                                  errors.itemDescription[index] &&
                                  errors.itemDescription[index].igst && (
                                    <span className="cstm-err">
                                      {errors.itemDescription[index].igst}
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="gstAmount"
                                  autocomplete="off"
                                  placeholder="GST Amount"
                                  value={item.GST_Total !== null ? item.GST_Total : ''}
                                  disabled
                                >
                                </input>
                              </div>
                            </td>
                          </>
                        }
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" placeholder="Amount" name="totalAmount"
                              value={item.Amount.toFixed(2)}
                              disabled
                            >
                            </input>
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip"
                                  id="tooltips1"
                                >
                                  Delete
                                </Tooltip>
                              }
                            ><span
                              className=""
                              onClick={(e) => handleRemove(index)}
                            >
                                <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
              </div>
            </div>

            <div className="Addmoreicon">
              <button type="button" className="addIcon" onClick={handleAdd}>
                <p>+</p>
              </button>
            </div>

            <div className="d-flex justify-content-end inv-gst-tbl-cont mt-3 mb-3">
              <div className="cstm-width">

                <div className="d-flex align-items-center mb-3">
                  <span className="gst-cont-label">Sub Total</span>
                  <span className="gst-cont-semicolumn">:</span>
                  <span className="gst-cont-input">
                    <input
                      type="text"
                      value={`${currencySymbol} ${subTotal.toFixed(2)}`}
                      className="form-control text-end"
                      disabled
                    />
                    {errors.subTotal && (
                      <div className="validation-bill-err">
                        {errors.subTotal}
                      </div>
                    )}
                  </span>
                </div>

                {!lineLevelCheck &&
                  <>
                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">CGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={cgst}
                          name="cgst"
                          className="form-control text-end"
                          placeholder="Enter CGST(%)"
                          onChange={(e) => handleTax(e, "Cgst")}
                          disabled={utgst || igst}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">SGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={sgst}
                          name="sgst"
                          className="form-control text-end"
                          placeholder="Enter SGST(%)"
                          onChange={(e) => handleTax(e, "Sgst")}
                          disabled={utgst || igst}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      <span className="gst-cont-label">UTGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={utgst}
                          name="utgst"
                          className="form-control text-end"
                          placeholder="Enter UTGST(%)"
                          onChange={(e) => handleTax(e, "Utgst")}
                          disabled={cgst || sgst || igst}
                        />
                      </span>
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      <span className="gst-cont-label">IGST(%)</span>
                      <span className="gst-cont-semicolumn">:</span>
                      <span className="gst-cont-input">
                        <input
                          type="text"
                          value={igst}
                          name="igst"
                          className="form-control text-end"
                          placeholder="Enter IGST(%)"
                          onChange={(e) => handleTax(e, "Igst")}
                          disabled={cgst || sgst || utgst}
                        />
                        {errors.tax && (
                          <p className="validation-bill-err">
                            {errors.tax}
                          </p>
                        )}
                      </span>
                    </div>
                  </>
                }

                <div className="d-flex align-items-center mb-2">
                  <span className="gst-cont-label">GST Total</span>
                  <span className="gst-cont-semicolumn">:</span>
                  <span className="gst-cont-input">
                    <input
                      type="text"
                      value={`${currencySymbol} ${gstTotal.toFixed(2)}`}
                      className="form-control text-end"
                      disabled
                    />
                  </span>
                </div>

                <div className="d-flex align-items-center mb-2">
                  <span className="gst-cont-label">Grand Total</span>
                  <span className="gst-cont-semicolumn">:</span>
                  <span className="gst-cont-input">
                    <input
                      type="text"
                      value={`${currencySymbol} ${grandTotal.toFixed(2)}`}
                      className="form-control text-end"
                      disabled
                    />
                  </span>
                </div>

              </div>
            </div>

            <div className="section-sub-heading">
              <h3>Files</h3>
            </div>

            <div className="pur-item-list">
              <div className="table-responsive tbl-body-shadow mb-20">
                <table className="w-100  table-theme-1">
                  <thead className="table-heads">
                    <tr>
                      <th className="text-left">File Name</th>
                      <th className="text-left">File Type</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {oldFiles.length > 0 ? (
                      oldFiles.map((row, index) => (
                        <tr key={index}>
                          <td className="text-left">{row.FileName}</td>
                          <td className="text-left">Pdf</td>
                          <td>
                            <div className="cursor-pointer d-flex align-items-center justify-content-center">
                              <div className="cstm-download-btn">
                                <Link
                                  to={`${BASE_IMAGE_URL}/${row.FilePath}`}
                                  download
                                  target="_blank"
                                >
                                  <button type="button" className="btn">
                                    <DownloadIcon /> Download
                                  </button>
                                </Link>
                              </div>
                              <div className="cstm-preview-file-btn ms-2">
                                <Link
                                  to={`/preview-pdf/${btoa(row.FilePath)}`}
                                  target="_blank"
                                >
                                  <button type="button" className="btn">
                                    <EyeIcon /> Preview
                                  </button>
                                </Link>
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    className="custom-tooltip"
                                    id="tooltips1"
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="ms-2"
                                  onClick={(e) => handleRemoveFile(index, e)}
                                >
                                  <DeleteIcon />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cstm-checkbox mb-2">
              <div className="cstm-checkbox-inner d-flex align-items-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  checked={attachDocuments}
                  onChange={(e) => setAttachDocuments(e.target.checked)}
                />
                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                  <label htmlFor="" className="ms-2">
                    Check for relevant document
                  </label>
                  <span className="required">*</span>
                  <span className=" infoIcon"></span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-xl-4 col-lg-4 col-md-5 position-relative">
                <div className="input-group mmm">
                  <input
                    type="file"
                    id="files"
                    className="form-control"
                    name="files"
                    disabled={!attachDocuments}
                    onChange={handleFileInputChange}
                    multiple
                  />
                </div>
                {errors.files && (
                  <p className="validation-bill-err">{errors.files}</p>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">

              <div className="cstm-success-btn ms-2">
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                >
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>

            </div>
          </div>

        </form >
      </div >
    </>
  );
};

export default EditIndividualBill;
